import { Injectable } from '@angular/core';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NavigationItem } from '@wizbii-components/bo-angular-ui';

const NavigationMenu: NavigationItem[] = [
  {
    route: FeaturesRoutingEnum.LDT,
    label: 'Lead Delivery Time',
    icon: 'picto/calendar-feature-test',
  },
  {
    route: FeaturesRoutingEnum.DF,
    label: 'Deployment Frequency',
    icon: 'picto/frequency',
  },
];

@Injectable({ providedIn: 'root' })
export class NavigationMenuService {
  readonly #navigationMenu = NavigationMenu;

  getMenu = (): NavigationItem[] => {
    return this.#navigationMenu;
  };
}
