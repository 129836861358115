import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii-utils/angular/core';

const version = require('../../package.json').version; // eslint-disable-line

const platform = getRuntimeEnvVar('PLATFORM');
const production = platform !== 'local';
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_DOMAIN');
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');

const deployAssetsUrl = production
  ? `https://storage.googleapis.com/wizbii-application-assets/accelerate-dashboard/${getBuildEnvVar('CI_COMMIT_SHA')}/fr/assets`
  : null;

export const environment = {
  applicationId: 'accelerate-dashboard',
  deployAssetsUrl,
  platform,
  production,
  domain: wizbiiDomain,
  appUrl: window.location.host,
  cookieDomain: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  api: {
    account: `https://account.api.${apiDomain}`,
    authentication: `https://auth.${apiDomain}`,
    domain: apiDomain,
    file: `https://file.${apiDomain}`,
    googleStorage: `https://storage.googleapis.com`,
    platform: apiPlatform,
    sso: `https://sso.internal.${wizbiiDomain}`,
    deployment: `https://www.${apiDomain}/_api/deployment`,
  },
  urls: {
    admiin: `https://admiin.${wizbiiTeamDomain}`,
  },
  wizbiiFiles: getRuntimeEnvVar('FILE_API_BUCKET'),
  version,
  bugsnag: {
    apiKey: '941baf18822ee58aaf5496a433b7569c',
  },
  deployUrl: window.location.hostname === 'localhost' ? `http://${window.location.host}/assets` : deployAssetsUrl,
};
