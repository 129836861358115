import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { JwtDelete, JwtSet, JwtState } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { Observable } from 'rxjs';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[] = [
    'https://storage.googleapis.com',
    new RegExp(
      `//auth.${environment.domain}(?!(/v1/(user|auth)).*/(email|overview|password|login-token|recovery-token))`,
      'i'
    ),
  ];

  constructor(
    private readonly store: Store,
    private readonly authService: AuthenticationWebservice
  ) {}

  getTokens(): Observable<JwtTokens> {
    return this.store.select(JwtState.state) as Observable<JwtTokens>;
  }

  logout(): void {
    this.store.dispatch(new JwtDelete());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.authService.refreshTokenV2(tokens, environment.applicationId);
  }

  saveLocalTokens(tokens: JwtTokens): void {
    this.store.dispatch(new JwtSet(tokens));
  }
}
