import { RemoveLDT, SetLDT } from './ldt.actions';
import { Injectable } from '@angular/core';
import { LDT } from '@models/LDT/ldt';
import { Action, Selector, State, StateContext } from '@ngxs/store';

interface LDTStateModel {
  ldt: LDT[];
}

@State<LDTStateModel>({
  name: 'ldt',
  defaults: { ldt: [] },
})
@Injectable()
export class LDTState {
  @Selector()
  static ldt(state: LDTStateModel): LDT[] | undefined {
    return state.ldt;
  }

  @Action(SetLDT)
  setLDT({ setState }: StateContext<LDTStateModel>, { ldt }: SetLDT): void {
    setState({ ldt: ldt.flatMap((ldt) => ldt.ldts) });
  }

  @Action(RemoveLDT)
  removeLDT({ getState, setState }: StateContext<LDTStateModel>, { ldt }: RemoveLDT): void {
    const state = getState();
    const filteredLDT = state.ldt.filter((l) => l.issue !== ldt.issue);
    setState({ ldt: filteredLDT });
  }
}
