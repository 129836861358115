import { AsyncPipe, DOCUMENT } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { NavigationMenuService } from '@commons/navigation-menu/navigation-menu.service';
import { CurrentRouteService } from '@commons/services/current-route.service';
import { Store } from '@ngxs/store';
import {
  NavigationItem,
  WzbBoNavigationSidebarMenuComponent,
  wzbBoNavigationSidebarMenuIconsDirective,
} from '@wizbii-components/bo-angular-ui';
import { JwtState } from '@wizbii-utils/angular/stores';
import { Observable, combineLatest, map, of } from 'rxjs';

@Component({
  selector: 'app-navigation-menu',
  standalone: true,
  imports: [AsyncPipe, WzbBoNavigationSidebarMenuComponent, wzbBoNavigationSidebarMenuIconsDirective, MatIconModule],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (vm$ | async; as vm) {
      <wzb-bo-navigation-sidebar-menu
        [reducible]="vm.menuConfig.reducible"
        [reduced]="vm.menuConfig.reduced"
        [reduceMode]="vm.menuConfig.reduceMode"
        [items]="vm.navigationItems"
        [activeRoute]="vm.currentRoute"
        (itemClicked)="onMenuItemClicked($event)"
      >
        @if (vm.isLogged) {
          <a [href]="vm.homeHref" menuLogo target="_self" aria-label="Aller à la page d'accueil">
            <mat-icon class="navigation-menu__logo" [svgIcon]="vm.logo" color="primary" />
          </a>
        }
        @for (item of vm.navigationItems; track trackByNavItem($index, item)) {
          <mat-icon *wzbBoNavigationSidebarMenuIcon="item.icon!" [svgIcon]="item.icon!" />
        }
      </wzb-bo-navigation-sidebar-menu>
    }
  `,
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationMenuComponent {
  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #window = inject(DOCUMENT).defaultView;

  readonly #layoutMenuService = inject(NavigationMenuService);
  readonly #isLogged$ = this.#store.select(JwtState.isLogged);

  readonly #navigationItems$: Observable<NavigationItem[]> = of(this.#layoutMenuService.getMenu());

  readonly #currentRoute$ = inject(CurrentRouteService).get();
  public vm$ = combineLatest([this.#isLogged$, this.#navigationItems$, this.#currentRoute$]).pipe(
    map(([isLogged, navigationItems, currentRoute]) => ({
      isLogged,
      navigationItems,
      currentRoute,
      homeHref: this.#window?.origin,
      logo: 'picto/obendy-white',
      menuConfig: {
        reduced: true,
        reducible: true,
        reduceMode: 'arrow' as 'arrow',
      },
    }))
  );

  public onMenuItemClicked({ route }: NavigationItem): void {
    this.#router.navigate(['/', route]);
  }

  readonly trackByNavItem = (_: number, item: NavigationItem): string => item.label!;
}
