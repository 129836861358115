import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/core.component';
import { reqBodyInterceptorFn } from '@core/interceptors/req-body.interceptor';
import { JwtService } from '@core/services/jwt/jwt.service';
import { WizbiiRouterStateSerializer } from '@core/services/serializer/router.serializer';
import { environment } from '@env/environment';
import { FEATURES_ROUTES } from '@features/features.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { NoopNgxsExecutionStrategy, provideStore } from '@ngxs/store';
import { DFState } from '@stores/df/df.state';
import { LDTState } from '@stores/ldt/ldt.state';
import { PageUnauthorizedModule } from '@wizbii-components/bo-angular-ui';
import { JWT_SERVICE_TOKEN, JwtInterceptor, JwtInterceptorFn } from '@wizbii-utils/angular/jwt';
import { JWT_STATE_CONFIG, JwtState } from '@wizbii-utils/angular/stores';
import { AUTHENTICATION_API_CONFIG, AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { ScrollToModule } from '@wizbii/ngx-scroll-to';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeFr, 'fr');

bootstrapApplication(CoreComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    provideHttpClient(withInterceptors([JwtInterceptorFn, reqBodyInterceptorFn])),
    provideRouter(
      FEATURES_ROUTES,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      })
    ),
    provideCharts(withDefaultRegisterables()),
    provideStore(
      [JwtState, LDTState, DFState],
      {
        developmentMode: !environment.production,
        executionStrategy: NoopNgxsExecutionStrategy,
        compatibility: {
          strictContentSecurityPolicy: true,
        },
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: true,
        },
      },
      withNgxsReduxDevtoolsPlugin({
        name: 'Accelerate Dashboard',
      }),
      withNgxsRouterPlugin()
    ),
    importProvidersFrom(MatDialogModule),
    importProvidersFrom(MatProgressBarModule),
    importProvidersFrom(LoadingBarHttpClientModule),
    importProvidersFrom(LoadingBarRouterModule),
    importProvidersFrom(PageUnauthorizedModule),
    importProvidersFrom(ScrollToModule.forRoot()),
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
    {
      provide: JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.cookieDomain,
      },
    },
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    { provide: LOCALE_ID, useValue: 'fr' },

    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
        cookieDomains: environment.cookieDomain,
      },
    },

    AuthenticationWebservice,
  ],
});
