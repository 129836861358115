import { environment } from '@env/environment';

export const bugsnagConfig = {
  apiKey: environment.bugsnag.apiKey,
  appVersion: environment.version,
  autoTrackSessions: false,
  releaseStage: environment.platform,
  isDeployed: environment.production,
  groupingRules: [],
};
