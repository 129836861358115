import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env/environment';
import { WzbBoNavigationSidebarMenuComponent } from '@wizbii-components/bo-angular-ui';

@Component({
  selector: 'app-interservice-navbar',
  standalone: true,
  imports: [WzbBoNavigationSidebarMenuComponent],
  template: ` <wiz-sidebar [environment]="env" menuOption />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InterserviceNavbarComponent {
  readonly env = environment.platform;
}
