import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { InterserviceNavbarComponent } from '@commons/interservice-navbar/interservice-navbar.component';
import { NavigationMenuComponent } from '@commons/navigation-menu/navigation-menu.component';
import { environment } from '@env/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-core',
  standalone: true,
  template: `
    @if (loadingBarService.value$ | async) {
      <mat-progress-bar class="progress-bar" mode="indeterminate" />
    }
    <app-interservice-navbar class="interservice-navbar" />
    <main class="main">
      <app-navigation-menu class="aside" />
      <section class="section">
        <router-outlet />
      </section>
    </main>
  `,
  styles: [
    `
      .main {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
      }

      .aside {
        position: sticky;
        top: 0;
        height: 100svh;
      }

      .version {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
      }

      /* Warning: keep z-index higher than header in builder-layout component */
      .interservice-navbar {
        position: fixed;
        bottom: 1.25rem;
        left: 2.375rem;
        z-index: 3;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NavigationMenuComponent, MatProgressBarModule, AsyncPipe, RouterOutlet, InterserviceNavbarComponent],
})
export class CoreComponent {
  readonly loadingBarService = inject(LoadingBarService);
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);

  constructor() {
    this.#matIconRegistry.addSvgIconSet(
      this.#domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployUrl}/sprites/picto.svg`)
    );
  }
}
