import { LDT, LDTProjectConfiguration } from '@models/LDT/ldt';

export class SetLDT {
  static readonly type = '[LDT] Set value';
  constructor(public ldt: LDTProjectConfiguration[]) {}
}

export class RemoveLDT {
  static readonly type = '[LDT] Remove value';
  constructor(public ldt: LDT) {}
}
