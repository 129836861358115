import { Route } from '@angular/router';
import { loggedGuard } from '@core/guards/logged.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';

const GUARDS = [loggedGuard];
export const FEATURES_ROUTES: Route[] = [
  {
    path: FeaturesRoutingEnum.LDT,
    canActivate: GUARDS,
    loadComponent: () => import('@features/ldt/ldt.component').then((c) => c.LdtComponent),
  },
  {
    path: FeaturesRoutingEnum.DF,
    canActivate: GUARDS,
    loadComponent: () => import('@features/df/df.component').then((c) => c.DFComponent),
  },
  { path: '', redirectTo: 'lead-delivery-time', pathMatch: 'full' },
];
